import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SnackErrorComponent } from '../../components/snack-error/snack.error';
import { LayoutService } from './layout.service';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase/compat/app';
import { SnackSuccessComponent } from 'src/app/components/snack-success/snack.success';
import { NavigationExtras, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { getJWTHeaders } from '../constants';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    userData: any; // Save logged in user data

    isMobile = false;

    failedAttempts = 0;
    failedEmail = null;

    signInObservable = new BehaviorSubject<any>(null);

    constructor(
        public afAuth: AngularFireAuth,
        public router: Router,
        private snackBar: MatSnackBar,
        private layoutService: LayoutService,
        public ngZone: NgZone,
        private http: HttpClient
    ) {
        this.isMobile = this.layoutService.device === 'mobile';
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user: any) => {
            if (user) {
                this.userData = user;
                localStorage.setItem('cixhealthAdminUser', JSON.stringify(this.userData));
            } else {
                localStorage.removeItem('cixhealthAdminUser');
            }
        });
    }

    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const parsableValue: any = localStorage.getItem('cixhealthAdminUser');
        const user: any = JSON.parse(parsableValue);
        return (user !== null && user.emailVerified !== false) ? true : false;
    }

    getCurrentUser() {
        const parsableValue: any = localStorage.getItem('cixhealthAdminUser');
        return JSON.parse(parsableValue);
    }

    getEntityId(): string {
        const parsableEmployer: any = localStorage.getItem('cixhealthEntityInfo');
        const entityId = JSON.parse(parsableEmployer)?.account?.id;

        return entityId;
    }

    // Sign in with email/password
    signIn(email: string, password: string) {
        return this.afAuth.signInWithEmailAndPassword(email, password)
            .then((result: any) => {
                this.ngZone.run(() => {
                    // call login to create or get the user object
                    localStorage.setItem('cixhealthAdmin', result?.user?.multiFactor?.user?.accessToken);
                    localStorage.setItem('cixhealthAdminUser', JSON.stringify(this.getUserDataObject(result.user)));

                    this.getUserInfo(result?.user?.uid).subscribe(userInfo => {
                        localStorage.setItem('cixhealthEntityInfo', JSON.stringify(userInfo));
                        if (!result?.user?.metadata?.lastLoginAt) {
                            const navigationExtras: NavigationExtras = { queryParams: { isNewUser: result?.additionalUserInfo?.isNewUser } };
                            this.router.navigate(['/manage-employees'], navigationExtras);
                        } else {
                            this.router.navigate(['/manage-employees']);
                        }
                    }, error => {
                        console.log(error, 'error getting app user');
                        this.signInObservable.next({ name: 'BadPassword' });
                    });
                }, (error: any) => {
                    this.signInObservable.next({ name: 'BadPassword' });
                    this.snackBar.openFromComponent(SnackErrorComponent, {
                        duration: 3000,
                        verticalPosition: this.isMobile ? 'bottom' : 'top',
                        data: {
                            contentText: error.message
                        }
                    });
                });
            }).catch((error: any) => {
                console.log('err in catch', error.error);
                this.signInObservable.next(true);
                this.snackBar.openFromComponent(SnackErrorComponent, {
                    duration: 3000,
                    verticalPosition: this.isMobile ? 'bottom' : 'top',
                    data: {
                        contentText: 'Incorrect login information, please try again or try resetting your password.'
                    }
                });
            });
    }

    // Sign in with Google
    googleAuth() {
        return this.authLogin(new firebase.auth.GoogleAuthProvider());
    }

    // Sign in with Google
    appleAuth() {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        return this.authLogin(provider);
    }

    // Auth logic to run auth providers
    authLogin(provider: any) {
        return this.afAuth.signInWithPopup(provider)
            .then((result: any) => {
                localStorage.setItem('cixhealthAdmin', result?.user?.multiFactor?.user?.accessToken);
                // this.getUserInfo();
                // this.setUserData(result.user);
                this.router.navigate(['/manage-employees']);
            }).catch((error: any) => {
                this.signInObservable.next(false);
            });
    }

    getUserDataObject(user: any) {
        return {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
        };
    }

    // Sign out
    signOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('cixhealthAdmin');
            localStorage.removeItem('cixhealthAdminUser');
            localStorage.removeItem('cix_admin_userId');

            this.ngZone.run(() => {
                this.router.navigate(['login']);
            });
        });
    }

    sendForgotPasswordLink(email: string) {
        this.afAuth.sendPasswordResetEmail(email)
            .then(() => {
                this.snackBar.openFromComponent(SnackSuccessComponent, {
                    duration: 3000,
                    verticalPosition: this.isMobile ? 'bottom' : 'top',
                    data: {
                        contentText: 'Forgot email sent, check your inbox for more instructions.'
                    }
                });
                this.router.navigate(['/login']);
            })
            .catch((error) => {
                console.log(error);
                this.snackBar.openFromComponent(SnackErrorComponent, {
                    duration: 3000,
                    verticalPosition: this.isMobile ? 'bottom' : 'top',
                    data: {
                        contentText: error.message
                    }
                });
            });
    }

    setUserPassword(emailAddress: string, password: string) {
        return this.http.post(`https://cixbrokeraffiliatemanageusers-${environment.apiEnvi}.azurewebsites.net/api/user/setAdminUserPassword`, { emailAddress, password }, getJWTHeaders());
    }


    getUserInfo(firebaseId: string) {
        return this.http.get(`https://cixbrokeraffiliatemanageusers-${environment.apiEnvi}.azurewebsites.net/api/users/getUserInfo/${firebaseId}`, getJWTHeaders());
    }
}
